import React, { useState } from "react";
import { APIAbonos, APIfacturas, APImovimientos } from "../../../../services/api/facturas";

export default function Dashboard() {
    const [fechaIni, setFechaIni] = useState('');
    const [fechaFin, setFechaFin] = useState('');
    const [fechaIniAbono, setFechaIniAbono] = useState('');
    const [fechaFinAbono, setFechaFinAbono] = useState('');
    const customWidth = {
        width: '20%',
        display: 'inline-block',
        paddingRight: '10px'
    };

    const getUrlExcel = () => {
        return APImovimientos.getExcel();
    }

    const getUrlExcelFacturas = (fechaIni, fechaFin) => {
        return APIfacturas.getExcelFacturas(fechaIni, fechaFin);
    };

    const getUrlExcelAbonos = (fechaIniAbono, fechaFinAbono) => {
        return APIAbonos.getExcelAbonos(fechaIniAbono, fechaFinAbono);
    };

    const getUrlExcelFacturasAntesCierre = () => {
        return APIfacturas.getExcelFacturasCierre();
    };

    const getUrlExcelFacturasFinMes = () => {
        return APIfacturas.getExcelFacturasFinMes();
    };

    const getUrlExcelAbonosFinMes = () => {
        return APIAbonos.getExcelAbonosFinMes();
    };

    const getUrlExcelAbonosAntesCierre = () => {
        return APIAbonos.getExcelAbonosCierre();
    };

    const handleExportMovimientosClick = async () => {
        const blob = await getUrlExcel();
        const link = document.createElement('a');
        const url = window.URL.createObjectURL(new Blob([blob]));
        link.href = url;
        link.setAttribute('download', 'Abonos.xlsx'); // Establecer el nombre del archivo a descargar
        document.body.appendChild(link);
        link.click(); // Simular el clic para iniciar la descarga
    
        // Limpiar el enlace después de la descarga
        document.body.removeChild(link);
    };

    const handleExportFacturasClick = async () => {
        const blob = await getUrlExcelFacturas(fechaIni, fechaFin);
        // Crear un enlace de descarga y forzar la descarga del archivo
        const link = document.createElement('a');
        const url = window.URL.createObjectURL(new Blob([blob]));
        link.href = url;
        link.setAttribute('download', 'Abonos.xlsx'); // Establecer el nombre del archivo a descargar
        document.body.appendChild(link);
        link.click(); // Simular el clic para iniciar la descarga
    
        // Limpiar el enlace después de la descarga
        document.body.removeChild(link);
    };

    const handleExportAbonosClick = async () => {
        const blob = await getUrlExcelAbonos(fechaIniAbono, fechaFinAbono);
    
        // Crear un enlace de descarga y forzar la descarga del archivo
        const link = document.createElement('a');
        const url = window.URL.createObjectURL(new Blob([blob]));
        link.href = url;
        link.setAttribute('download', 'Abonos.xlsx'); // Establecer el nombre del archivo a descargar
        document.body.appendChild(link);
        link.click(); // Simular el clic para iniciar la descarga
    
        // Limpiar el enlace después de la descarga
        document.body.removeChild(link);
    };

    const handleExportFacturasFinMesClick = async () => {
        const blob = await getUrlExcelFacturasFinMes(); // Obtener el blob con el archivo Excel
    
        // Crear un enlace de descarga y forzar la descarga del archivo
        const link = document.createElement('a');
        const url = window.URL.createObjectURL(new Blob([blob]));
        link.href = url;
        link.setAttribute('download', 'FacturasPagarFinMes.xlsx'); // Establecer el nombre del archivo a descargar
        document.body.appendChild(link);
        link.click(); // Simular el clic para iniciar la descarga
    
        // Limpiar el enlace después de la descarga
        document.body.removeChild(link);
    };

    const handleExportAbonosFinMesClick = async () => {
        const blob = await getUrlExcelAbonosFinMes(); // Obtener el blob con el archivo Excel
    
        // Crear un enlace de descarga y forzar la descarga del archivo
        const link = document.createElement('a');
        const url = window.URL.createObjectURL(new Blob([blob]));
        link.href = url;
        link.setAttribute('download', 'AbonosPagarFinMes.xlsx'); // Establecer el nombre del archivo a descargar
        document.body.appendChild(link);
        link.click(); // Simular el clic para iniciar la descarga
    
        // Limpiar el enlace después de la descarga
        document.body.removeChild(link);
    };

    const handleExportFacturasAntesCierreClick = async () => {
        const blob = await getUrlExcelFacturasAntesCierre(); // Obtener el blob con el archivo Excel
    
        // Crear un enlace de descarga y forzar la descarga del archivo
        const link = document.createElement('a');
        const url = window.URL.createObjectURL(new Blob([blob]));
        link.href = url;
        link.setAttribute('download', 'FacturasAntesCierre.xlsx'); // Establecer el nombre del archivo a descargar
        document.body.appendChild(link);
        link.click(); // Simular el clic para iniciar la descarga
    
        // Limpiar el enlace después de la descarga
        document.body.removeChild(link);
    };

    const handleExportAbonosAntesCierreClick = async () => {
        const blob = await getUrlExcelAbonosAntesCierre(); // Obtener el blob con el archivo Excel
    
        // Crear un enlace de descarga y forzar la descarga del archivo
        const link = document.createElement('a');
        const url = window.URL.createObjectURL(new Blob([blob]));
        link.href = url;
        link.setAttribute('download', 'AbonosAntesCierre.xlsx'); // Establecer el nombre del archivo a descargar
        document.body.appendChild(link);
        link.click(); // Simular el clic para iniciar la descarga
    
        // Limpiar el enlace después de la descarga
        document.body.removeChild(link);
    };

    return (
        <div className="content pt-3">
            <h1>Exportadores</h1>
            <p className="mb-2">Selecciona los filtros para generar una exportación</p>

            <fieldset className="export-fieldset">
                <legend>Exportar movimientos</legend>
                <div style={customWidth} className="flex flex-col flex-1">
                    <button
                        className="btn btn-success"
                        onClick={handleExportMovimientosClick}
                    >
                        Exportar a excel
                    </button>
                </div>
            </fieldset>
            <fieldset className="export-fieldset">
                <legend>Exportar facturas</legend>
                <div style={{ display: 'flex', justifyContent: 'flex-start', paddingRight: '10px' }}>
                    <div style={{ marginRight: '10px' }}>
                        <label>Fecha Inicial:</label>
                        <input
                            type="date"
                            value={fechaIni}
                            onChange={(e) => setFechaIni(e.target.value)}
                        />
                    </div>

                    <div style={{ marginRight: '10px' }}>
                        <label>Fecha Final:</label>
                        <input
                            type="date"
                            value={fechaFin}
                            onChange={(e) => setFechaFin(e.target.value)}
                        />
                    </div>

                    <button className="btn btn-primary" onClick={handleExportFacturasClick}>Exportar facturas</button>
                </div>
            </fieldset>
            <fieldset className="export-fieldset">
                <legend>Exportar facturas para pagar a fin de mes</legend>
                <div style={{ display: 'flex', justifyContent: 'flex-start', paddingRight: '10px' }}>
                    <button className="btn btn-primary" onClick={handleExportFacturasFinMesClick}>Exportar facturas</button>
                </div>
            </fieldset>
            <fieldset className="export-fieldset">
                <legend>Exportar facturas antes de cierre</legend>
                <div style={{ display: 'flex', justifyContent: 'flex-start', paddingRight: '10px' }}>
                    <button className="btn btn-primary" onClick={handleExportFacturasAntesCierreClick}>Exportar facturas</button>
                </div>
            </fieldset>
            <fieldset className="export-fieldset">
                <legend>Exportar abonos</legend>
                <div style={{ display: 'flex', justifyContent: 'flex-start', paddingRight: '10px' }}>
                    <div style={{ marginRight: '10px' }}>
                        <label>Fecha Inicial:</label>
                        <input
                            type="date"
                            value={fechaIniAbono}
                            onChange={(e) => setFechaIniAbono(e.target.value)}
                        />
                    </div>

                    <div style={{ marginRight: '10px' }}>
                        <label>Fecha Final:</label>
                        <input
                            type="date"
                            value={fechaFinAbono}
                            onChange={(e) => setFechaFinAbono(e.target.value)}
                        />
                    </div>

                    <button className="btn btn-primary" onClick={handleExportAbonosClick}>Exportar abonos</button>
                </div>
            </fieldset>
            <fieldset className="export-fieldset">
                <legend>Exportar abonos para pagar a fin de mes</legend>
                <div style={{ display: 'flex', justifyContent: 'flex-start', paddingRight: '10px' }}>
                    <button className="btn btn-primary" onClick={handleExportAbonosFinMesClick}>Exportar abonos</button>
                </div>
            </fieldset>
            <fieldset className="export-fieldset">
                <legend>Exportar abonos antes de cierre</legend>
                <div style={{ display: 'flex', justifyContent: 'flex-start', paddingRight: '10px' }}>
                    <button className="btn btn-primary" onClick={handleExportAbonosAntesCierreClick}>Exportar abonos</button>
                </div>
            </fieldset>
        </div>
    );
}
