import Pagination from '@mui/material/Pagination';
import { format } from 'date-fns';
import React, { useEffect, useState } from "react";
import { Col, Tab, Tabs } from 'react-bootstrap';
import { useForm, Controller } from "react-hook-form";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import done from "../../../../images/icon_done.png";
import pending from "../../../../images/icon_pending.png";
import write_nota from "../../../../images/write_icon.png";
import delete_abono from "../../../../images/delete.png";
import { APIAbonos, APImovimientos, APIOcr } from "../../../../services/api/facturas";
import AbonoViewerDialog from "../Movimientos/FacturasList/Dialogs/AbonoViewerDialog";
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';

export default function Abonos() {
    const {
        register,
        control,
        reset,
        watch,
        setValue,
        formState: { errors },
        handleSubmit
    } = useForm({});
    const [key, setKey] = useState('form');
    const [pageCount, setPageCount] = useState(1);
    const [pageCountAbonos, setPageCountAbonos] = useState(1);
    const limit = 15;
    const [movimientos, setMovimientos] = useState([]);
    const [abonos, setAbonos] = useState([]);
    const [filters, setFilters] = useState(false);
    const [filtersAbonos, setFiltersAbonos] = useState(false);
    //currentPage Movimientos
    const currPage = parseInt(1);
    const [currentPage, setPage] = useState(currPage);
    //currentPage Abonos
    const currPageAbono = parseInt(1);
    const [currentPageAbono, setPageAbono] = useState(currPageAbono);
    // Estados para los valores seleccionados en los desplegables select
    //Filtros para movimientos
    const [selectedDesc, setSelectedDesc] = useState(null);
    const [selectedNombre, setSelectedNombre] = useState(null);
    const [selectedCifnif, setSelectedCifnif] = useState(null);
    const [selectedEstado, setSelectedEstado] = useState(null);
    //Filtros para abonos
    const [selectedMov, setSelectedMov] = useState(null);
    const [selectedNumero, setSelectedNumero] = useState(null);
    const [selectedEmpresa, setSelectedEmpresa] = useState(null);
    const [selectedProv, setSelectedProv] = useState(null);
    const [movimientoSelected, setMovimientoSelected] = useState(null);
    const [selectedMovimiento, setSelectedMovimiento] = useState(null);
    const [selectedProvNombre, setSelectedProvNombre] = useState(null);
    const [selectedProveedor, setSelectedProveedor] = useState(null);
    const [selectedIdProv, setSelectedIdProv] = useState(null);
    const [selectedPFM, setSelectedPFM] = useState(null);
    const [selectedCuentaNombre, setSelectedCuentaNombre] = useState(null);
    const [selectedCuenta, setSelectedCuenta] = useState(null);
    const [selectedViesNombre, setSelectedViesNombre] = useState(null);
    const [selectedVies, setSelectedVies] = useState(null);
    const [isViesRequired, setIsViesRequired] = useState(true);
    const [isCuentaRequired, setIsCuentaRequired] = useState(true);
    //objeto para traspasar abono al form register
    const [abono, setAbono] = useState({});
    const [abonoViewerOpen, setAbonoViewerOpen] = useState(false);
    const [abonoIdToShow, setAbonoIdToShow] = useState(null);
    const [fechaIni, setFechaIni] = useState('');
    const [fechaFin, setFechaFin] = useState('');
    const [viewerSrc, setViewerSrc] = useState(null);
    const estados = [
        { value: "", label: "Filtrar por Estado" },
        { value: ">0", label: "Pendientes" },
        { value: "0", label: "Finalizadas" },
    ];
    const [dateRange, setDateRange] = useState({ startDate: '', endDate: '' });
    const [dateAbonoRange, setDateAbonoRange] = useState({ startAbonoDate: '', endAbonoDate: '' });
    const tipoRetencionSeleccionada = watch("tipo_retencion");
    const handleDateChange = (event) => {
        const { name, value } = event.target;
        setDateRange(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleReset = () => {
        setDateRange({
            startDate: '',
            endDate: ''
        });
    };

    const handleDateAbonoChange = (event) => {
        const { name, value } = event.target;
        setDateAbonoRange(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleAbonoReset = () => {
        setDateAbonoRange({
            startAbonoDate: '',
            endAbonoDate: ''
        });
    };

    const fetchDataMovimientos = async () => {
        const ftr = [];
        if (selectedDesc != null && selectedDesc != '') {
            ftr.push("description|" + selectedDesc.description);
        }
        if (selectedNombre != null && selectedNombre != '') {
            ftr.push("nombre|" + selectedNombre.nombre);
        }
        if (selectedCifnif != null && selectedCifnif != '') {
            ftr.push("cifnif|" + selectedCifnif.cifnif);
        }
        if (selectedEstado != null && selectedEstado != '') {
            ftr.push("restante|" + selectedEstado);
        }
        if (dateRange.startDate && dateRange.endDate) {
            ftr.push("cierre|1");
            ftr.push('created_at|>=' + dateRange.startDate)
            ftr.push('created_at|<=' + dateRange.endDate)
        } else {
            ftr.push("cierre|IS NULL");
        }
        try {
            const data = await APImovimientos.post({ limit, currPage, ftr });
            setMovimientos(data.movimientos);
            setPageCount(data.count);
            sanitizeFilters(data.filters);
            showToast('Obtenidos datos de movimientos');
        } catch (err) {
            showErrorToast('No se han podido obtener los datos de movimientos.');
        }
    };

    const fetchDataAbonos = async () => {
        const ftr = [];
        if (selectedMov != null && selectedMov.value != '') {
            ftr.push("mov|" + selectedMov.value);
        }
        if (selectedNumero != null && selectedNumero.value != '') {
            ftr.push("numero|" + selectedNumero.value);
        }
        if (selectedEmpresa != null && selectedEmpresa.value != '') {
            ftr.push("localizacion|" + selectedEmpresa.value);
        }
        if (selectedIdProv != null && selectedIdProv.value != '') {
            ftr.push("prov_facturas_abonos.prov_map_proveedor_id|" + selectedIdProv.value);
        }
        if (selectedPFM != null && selectedPFM != '') {
            if (selectedPFM.value === "1") {
                ftr.push("pago_fin_mes|" + selectedPFM.value);
            }
        }
        if (dateAbonoRange.startAbonoDate && dateAbonoRange.endAbonoDate) {
            ftr.push("cierre|1");
            ftr.push('created_at|>=' + dateAbonoRange.startAbonoDate)
            ftr.push('created_at|<=' + dateAbonoRange.endAbonoDate)
        } else {
            ftr.push("cierre|IS NULL");
        }
        try {
            const data = await APIAbonos.listNoMov({ limit, currPageAbono, ftr });
            setAbonos(data.abonos);
            if (!selectedProvNombre) {
                const listProveedores = await APIAbonos.listProveedoresCredit();
                setSelectedProvNombre(listProveedores.proveedores);
            }
            if (!selectedCuentaNombre) {
                const listCuentas = await APIAbonos.listCuentas();
                setSelectedCuentaNombre(listCuentas.cuentas);
            }
            if (!selectedViesNombre) {
                const listVies = await APIAbonos.listVies();
                setSelectedViesNombre(listVies.vies);
            }
            sanitizeFiltersAbonos(data.filters);
            setPageCountAbonos(data.count);
            showToast('Abonos obtenidos');
        } catch (err) {
            showErrorToast('No se han podido obtener los abonos');
        }
    };

    useEffect(() => {
        fetchDataMovimientos();
    }, [selectedDesc, selectedNombre, selectedCifnif, selectedEstado, dateRange]);

    useEffect(() => {
        fetchDataAbonos();
    }, [selectedNumero, selectedEmpresa, selectedProv, selectedPFM, selectedMov, selectedIdProv, dateAbonoRange]);

    useEffect(() => {
        // Configurar los valores iniciales después de que el formulario se haya registrado
        if (abono) {
            if (abono.numero) {
                setValue('numero', abono.numero);
            }
            if (abono.total) {
                setValue('total', abono.total);
            }
            if (abono.fecha) {
                setValue('date', format(new Date(abono.fecha), 'dd/MM/yyyy'));
            }
            if (abono && selectedProveedor) {
                setValue('prov_map_proveedor_id', selectedProveedor);
            }
            if (abono && selectedCuenta) {
                setValue('netsuite_account_id', selectedCuenta);
            }
            if (abono && selectedVies) {
                setValue('vies', selectedVies);
            }
            if (abono.tipo_iva) {
                setValue('tipo_iva', abono.tipo_iva);
            }
            if (abono.tipo_retencion) {
                setValue('tipo_retencion', abono.tipo_retencion);
            }
            if (abono.retencion) {
                setValue('retencion', abono.retencion);
            }
            setValue('iva_deducible', abono.iva_deducible);
            setValue('pago_fin_mes', abono.pago_fin_mes);
            setValue('localizacion', abono.localizacion);
            setValue('departamento', abono.departamento);
            setValue('clasif', abono.clasif);
        }
    }, [abono, setValue]);

    const handleAsociarClick = (abono) => {
        setAbono(abono);
        setSelectedProveedor(abono.prov_map_proveedor_id);
        setIsViesRequired(false); // Desactiva la obligatoriedad del campo
        setIsCuentaRequired(false); // Desactiva la obligatoriedad del campo
        setKey('form');
    };

    const handleFileChange = async (e) => {
        const file = e.target.files[0];

        if (file) {
            try {
                // Abrir la vista previa del archivo
                setViewerSrc(URL.createObjectURL(file));

                // Crea un objeto FormData y agrega el archivo con la clave 'pdf'
                const formData = new FormData();
                formData.append('pdf', file);

                // Llamada a la API utilizando fetch o axios (dependiendo de tu implementación)
                const response = await APIOcr.post(formData);
                // Actualizar los valores en los campos de entrada
                setValue('numero', response.numeroFactura || "");
                setValue('total', response.totalIva || "");
                setValue('date', response.fechaFactura || "");
                setValue('base', response.base || "");
                setValue('iva', response.iva || "");
            } catch (error) {
                console.error('Error:', error.message);
            }
        }
    };

    const handleSelect = (k) => {
        setKey(k);
    };

    const removeAbono = async (id) => {
        const docResult = await APIAbonos.delete(id);
        fetchDataAbonos();
    }

    const handleOpenAbonoViewer = (abonoId) => {
        setAbonoIdToShow(abonoId);
        setAbonoViewerOpen(true);
    };

    const handleCloseAbonoViewer = () => {
        setAbonoViewerOpen(false);
        setAbonoIdToShow(null); // Limpiamos el id después de cerrar el visor
    };

    const handleCheckboxChange = (movimientoId) => {
        // Desmarca el movimiento previamente seleccionado, si lo hay
        if (selectedMovimiento === movimientoId) {
            setMovimientoSelected(null);
            setSelectedMovimiento(null);
        } else {
            // Marca el nuevo movimiento seleccionado
            setMovimientoSelected(movimientoId);
            setSelectedMovimiento(movimientoId);
        }
    };

    const handleChangeBaseNoSujeta = (event) => {
        let baseNoSujeta = event.target.value;
        baseNoSujeta = baseNoSujeta.replace(',', '.');
        setValue('baseNoSujeta', baseNoSujeta);
    };

    const sanitizeFilters = (filters) => {
        filters.dDesc[1].pop()
        filters.dNombre[1].pop()
        filters.dCifnif[1].pop()
        setFilters(filters);
    };

    const sanitizeFiltersAbonos = (filters) => {
        filters.dNumero[1].pop()
        filters.dNombre[1].pop()
        filters.dNombre[1].pop()
        setFiltersAbonos(filters);
    };

    const showToast = (message) => {
        toast.info(message, {
            toastId: "info-movs",
            position: 'top-right', // You can customize the position
        });
    };

    const showErrorToast = (message) => {
        toast.error(message, {
            position: 'top-right', // You can customize the position
        });
    };

    const handleCloseViewer = () => {
        setViewerSrc(null);
    };

    const performAction = () => {
        showToast('Abono procesado correctamente');
        setAbono(null);
        setSelectedMovimiento(null);
        setSelectedProveedor(null);
        setValue('localizacion', null);
        setValue('departamento', null);
        setValue('clasif', null);
        setSelectedCuenta(null);
        setSelectedVies(null);
        reset();
        fetchDataMovimientos();
        fetchDataAbonos();
        setIsViesRequired(true); // Reactivata la obligatoriedad del campo
        setIsCuentaRequired(true); // Reactivata la obligatoriedad del campo
    }

    const submit = async (formData) => {
        const documentoForm = new FormData();
        Object.entries(formData).forEach((value) => {
            if (value[0] === 'documento' && value[1]?.length > 0) {
                documentoForm.append(value[0], value[1][0]);
            } else {
                documentoForm.append(value[0], value[1]);
            }
        });
        //Es decir tenemos abono creado previamente, deberemos actualizar la asociación del movimiento
        if (abono && abono.id) {
            if (!selectedMovimiento) {
                showErrorToast('Debes seleccionar un movimento para asociar al abono');
                return;
            } else {
                //update abono asociando movimiento
                try {
                    documentoForm.append("id", selectedMovimiento)
                    const res = await APIAbonos.putAbonoNoMov(documentoForm);
                    performAction();
                    return;
                } catch (err) {
                    showErrorToast('Error al procesar el abono. Por favor, inténtalo de nuevo más tarde.');
                    return;
                }
            }
        }
        if (!selectedMovimiento) {
            try {
                const res = await APIAbonos.postAbonoNoMov(documentoForm);
                performAction();
            } catch (err) {
                if (err.response && err.response.status === 400) {
                    showErrorToast('Importes inválidos');
                } else {
                    showErrorToast('Error al procesar el abono. Por favor, inténtalo de nuevo más tarde.');
                }
            }
        } else {
            documentoForm.append("id", selectedMovimiento)
            try {
                const res = await APIAbonos.post(documentoForm);
                performAction();
            } catch (err) {
                if (err.response && err.response.status === 400) {
                    showErrorToast('Importes inválidos');
                } else {
                    showErrorToast('Error al procesar el abono. Por favor, inténtalo de nuevo más tarde.');
                }
            }
        }
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
        const ftr = [];
        if (selectedDesc != null && selectedDesc != '') {
            ftr.push("description|" + selectedDesc.description);
        }
        if (selectedNombre != null && selectedNombre != '') {
            ftr.push("nombre|" + selectedNombre.nombre);
        }
        if (selectedCifnif != null && selectedCifnif != '') {
            ftr.push("cifnif|" + selectedCifnif.cifnif);
        }
        if (selectedEstado != null && selectedEstado != '') {
            ftr.push("restante|" + selectedEstado);
        }
        if (dateRange.startDate && dateRange.endDate) {
            ftr.push("cierre|1");
            ftr.push('created_at|>=' + dateRange.startDate)
            ftr.push('created_at|<=' + dateRange.endDate)
        } else {
            ftr.push("cierre|IS NULL");
        }
        const ftrParams = ftr.map(filter => `filter=${filter}`).join('&');
        APImovimientos.get(limit, newPage, ftrParams).then((data) => {
            setMovimientos(data.movimientos);
        }).catch((err) => {

        })
    }

    const handleChangePageAbonos = async (event, newPage) => {
        setPageAbono(newPage);
        const ftr = [];
        if (selectedMov != null && selectedMov.value != '') {
            ftr.push("mov|" + selectedMov.value);
        }
        if (selectedNumero != null && selectedNumero.value != '') {
            ftr.push("numero|" + selectedNumero.value);
        }
        if (selectedEmpresa != null && selectedEmpresa.value != '') {
            ftr.push("localizacion|" + selectedEmpresa.value);
        }
        if (selectedProv != null && selectedProv != '') {
            ftr.push("prov_facturas_abonos.prov_map_proveedor_id|" + selectedProv.value);
        }
        if (selectedPFM != null && selectedPFM != '') {
            if (selectedPFM.value === "1") {
                ftr.push("pago_fin_mes|" + selectedPFM.value);
            }
        }
        if (dateAbonoRange.startAbonoDate && dateAbonoRange.endAbonoDate) {
            ftr.push("cierre|1");
            ftr.push('created_at|>=' + dateAbonoRange.startAbonoDate)
            ftr.push('created_at|<=' + dateAbonoRange.endAbonoDate)
        } else {
            ftr.push("cierre|IS NULL");
        }
        let page = newPage;
        try {
            const data = await APIAbonos.listNoMov({ limit, page, ftr });
            setAbonos(data.abonos);
        } catch (err) {
            showErrorToast('No se han podido obtener los abonos');
        }
    }

    function flattenArray(arr) {
        if (!Array.isArray(arr)) {
            return [];
        }
        return arr.reduce((acc, val) => acc.concat(val), []);
    }

    const handleChangeTipoIva = (event) => {
        let total = event.target.value;
        total = total.replace(',', '.');
        let base = 0;
        let tipo_iva = document.getElementById('tipo_iva').value;
        let iva = 0;
        setValue('tipo_iva', tipo_iva);
        if (tipo_iva === "21") {
            base = (total / 1.21).toFixed(2);
            setValue('base', base);
        } else if (tipo_iva === "10") {
            base = (total / 1.10).toFixed(2);
            setValue('base', base);
        } else if (tipo_iva === "0") {
            iva = 0;
            base = total;
            setValue('base', total);
        }
        iva = (total - base).toFixed(2)
        setValue('iva', iva);
        setValue('total', total);
    };

    const customWidth = {
        width: '14%',
        display: 'inline-block',
        paddingRight: '10px'
    };

    const customWidthAbonos = {
        width: '15%',
        display: 'inline-block',
        paddingRight: '10px'
    };

    const getRows = (listMovimientos) => {

        return listMovimientos.map((movimiento, index) => {
            const isChecked = movimiento.id === selectedMovimiento
            return <tr className={movimiento.color ? 'bookmark' : ''} style={{ borderLeftColor: movimiento.color, color: movimiento.restante === 0 ? "darkgreen" : undefined }}
                key={`mov-${index}`}>
                <td style={{ width: "3%", fontSize: "100%" }}>
                    <input
                        type="checkbox"
                        checked={isChecked}
                        onChange={() => handleCheckboxChange(movimiento.id)}
                    />
                </td>
                <td style={{ width: "3%", fontSize: "100%" }}>
                    <img src={movimiento.restante === 0 ? done : pending} alt="status-mov" style={{ height: "36px", cursor: "pointer" }}></img>
                </td>
                <td style={{ width: "5%", fontSize: "100%" }}>{format(new Date(movimiento.fecha), 'dd/MM/yyyy')}</td>
                <td style={{ width: "10%", fontSize: "100%" }}>{movimiento.nombre}</td>
                <td style={{ width: "5%", fontSize: "100%" }}>{movimiento.cifnif}</td>
                <td style={{ width: "20%", fontSize: "100%" }}>{movimiento.description}</td>
                <td style={{ width: "5%", fontSize: "100%" }}>{movimiento.base}</td>
                <td style={{ width: "5%", fontSize: "100%" }}>{movimiento.iva}</td>
                <td style={{ width: "5%", fontSize: "100%" }}>{movimiento.total}</td>
                <td style={{ width: "5%", fontSize: "100%" }}>{movimiento.restante}</td>
                <td style={{ width: "5%", fontSize: "100%" }}>
                </td>
            </tr>
        })
    }

    const getRowsAbonos = (listAbonos) => {

        return listAbonos.map((abono, index) => {
            return <tr className={abono.color ? 'bookmark' : ''} style={{ borderLeftColor: abono.color, color: abono.restante === 0 ? "darkgreen" : undefined }}
                key={`mov-${index}`}>
                <td style={{ width: "3%", fontSize: "100%" }}>
                    {abono.prov_abonos_movs_id === null ? (
                        <button className='btn btn-primary' onClick={() => handleAsociarClick(abono)}>Asociar</button>
                    ) : (
                        <img
                            src={done}
                            alt="status-mov"
                            style={{ height: "36px", cursor: "pointer" }}
                        />
                    )}
                </td>
                <td style={{ width: "10%", fontSize: "100%" }}>{abono.numero}</td>
                <td style={{ width: "5%", fontSize: "100%" }}>{format(new Date(abono.fecha), 'dd/MM/yyyy')}</td>
                <td style={{ width: "10%", fontSize: "100%" }}>{abono.nombre_proveedor}</td>
                <td style={{ width: "5%", fontSize: "100%" }}>{abono.base}</td>
                <td style={{ width: "5%", fontSize: "100%" }}>{abono.iva}</td>
                <td style={{ width: "5%", fontSize: "100%" }}>{abono.total}</td>
                <td style={{ width: "3%", fontSize: "100%" }}>
                    <button onClick={() => handleOpenAbonoViewer(abono.id)}>
                        <img src={write_nota} alt="documento-icon" style={{ height: "28px" }} />
                    </button>
                </td>
                <td style={{ width: "3%", fontSize: "100%" }}>
                    <button onClick={() => removeAbono(abono.id)}>
                        <img src={delete_abono} alt="elininar-icon" style={{ height: "28px" }} />
                    </button>
                </td>
                {/* Mostrar el visor de archivos si abonoIdToShow tiene un valor */}
                {abonoIdToShow && abonoIdToShow === abono.id && (
                    <AbonoViewerDialog
                        abonoId={abonoIdToShow}
                        open={abonoViewerOpen}
                        onClose={handleCloseAbonoViewer}
                    />
                )}
            </tr>
        })
    }

    return (
        <div className="content pt-3">
            <ToastContainer />
            <h1 className="mb-2">Abonos</h1>
            <p className="mb-2">Introduce los abonos asociados (o no) a un movimiento</p>
            <Tabs
                id="controlled-tab-example"
                activeKey={key}
                onSelect={handleSelect}
                className="mb-3"
            >
                <Tab eventKey="form" title="Formulario">
                    <form id="abonosForm" onSubmit={handleSubmit(submit)}>
                        <div className="row">
                            <Col md={3}>
                                <div className="w-100 d-flex flex-column">
                                    <div className="d-flex flex-wrap justify-content-between">

                                        <div className="form-group" style={{ marginBottom: "0rem" }}>
                                            <label htmlFor="documento">Abono a subir</label>
                                            {abono && abono.name ? (
                                                <div>
                                                    <p>Abono cargado: {abono.name}</p>
                                                    <input
                                                        type="hidden"
                                                        className="form-control"
                                                        defaultValue={abono.id ? abono.id : ''}
                                                        {...register("abonoid")}
                                                    />
                                                    <input
                                                        type="hidden"
                                                        className="form-control"
                                                        defaultValue={abono.name ? abono.name : ''}
                                                        {...register("originalname")}
                                                    />
                                                </div>
                                            ) : (
                                                <>
                                                    <input
                                                        type="file"
                                                        className="form-control"
                                                        defaultValue={abono ? abono.name : ''}
                                                        {...register("documento", { required: true, maxLength: 255 })}
                                                        onChange={handleFileChange}
                                                    />
                                                    <div className="form-invalid">
                                                        {(errors.documento) && "El documento es obligatorio!"}
                                                    </div>
                                                    <hr />
                                                </>
                                            )}
                                        </div>
                                        <div className="form-group" style={{ marginBottom: "1rem", flex: "0 0 48%", width: "50%" }}>
                                            <label htmlFor="prov_map_proveedor_id" style={{ marginTop: "5px" }}>Proveedor</label>
                                            <Controller
                                                name="prov_map_proveedor_id"
                                                control={control}
                                                rules={{ required: !selectedMovimiento }}
                                                defaultValue={null}
                                                render={({ field }) => (
                                                    <Autocomplete
                                                        {...field}
                                                        options={flattenArray(selectedProvNombre)}
                                                        getOptionLabel={(option) => option.nombre}
                                                        onChange={(event, newValue) => {
                                                            setValue("prov_map_proveedor_id", newValue ? newValue.id : null);
                                                            setSelectedProveedor(newValue ? newValue.id : null);
                                                        }}
                                                        value={flattenArray(selectedProvNombre).find((item) => item.id === selectedProveedor) || null}
                                                        renderInput={(params) => (
                                                            <TextField
                                                                {...params}
                                                                label="Selecciona un Proveedor"
                                                                variant="outlined"
                                                            />
                                                        )}
                                                        noOptionsText="No hay opciones para los criterios introducidos"
                                                    />
                                                )}
                                            />
                                            <div className="form-invalid">
                                                {(errors.prov_map_proveedor_id) && "Selecciona un proveedor o marca un movimiento asociado"}
                                            </div>
                                        </div>
                                        {isViesRequired && (
                                            <div className="form-group" style={{ marginBottom: "1rem", flex: "0 0 48%", width: "50%" }}>
                                                <label htmlFor="vies" style={{ marginTop: "5px" }}>Vies</label>
                                                <Controller
                                                    name="vies"
                                                    control={control}
                                                    rules={{ required: true }}
                                                    defaultValue={null}
                                                    render={({ field }) => (
                                                        <Autocomplete
                                                            {...field}
                                                            options={flattenArray(selectedViesNombre)}
                                                            getOptionLabel={(option) => option.nombre}
                                                            onChange={(event, newValue) => {
                                                                setValue("vies", newValue ? newValue.id : null);
                                                                setSelectedVies(newValue ? newValue.id : null);
                                                            }}
                                                            value={flattenArray(selectedViesNombre).find((item) => item.id === selectedVies) || null}
                                                            renderInput={(params) => (
                                                                <TextField
                                                                    {...params}
                                                                    label="Selecciona un vies"
                                                                    variant="outlined"
                                                                />
                                                            )}
                                                            noOptionsText="No hay opciones para los criterios introducidos"
                                                        />
                                                    )}
                                                />
                                                <div className="form-invalid">
                                                    {(errors.vies) && "Selecciona un vies"}
                                                </div>
                                            </div>
                                        )}
                                        <div className="form-group" style={{ marginBottom: "1rem", flex: "0 0 48%", width: "50%" }}>
                                            <label style={{ marginTop: "5px" }} htmlFor="localizacion">Localización</label>
                                            <select id="localizacion" {...register("localizacion", { required: true })}>
                                                <option value="1">Lleida</option>
                                                <option value="2">Balaguer</option>
                                                <option value="3">Zaragoza</option>
                                                <option value="4">Vinarós</option>
                                                <option value="6">Segovia</option>
                                            </select>
                                        </div>
                                        <div className="form-group" style={{ marginBottom: "1rem", flex: "0 0 48%", width: "50%" }}>
                                            <label style={{ marginTop: "5px" }} htmlFor="departamento">Departamento</label>
                                            <select id="departamento" {...register("departamento", { required: true })}>
                                                <option value="1">Finanzas</option>
                                                <option value="2">Incidencias</option>
                                                <option value="3">IT</option>
                                                <option value="4">Recambistas</option>
                                                <option value="5">RRHH</option>
                                                <option value="6">Canal Venta Online</option>
                                                <option value="7">Canal Venta Dropshipping</option>
                                                <option value="8">Producción</option>
                                                <option value="9">Headquarters</option>
                                                <option value="10">Canal Raw Material</option>
                                            </select>
                                        </div>
                                        <div className="form-group" style={{ marginBottom: "1rem", flex: "0 0 48%", width: "50%" }}>
                                            <label style={{ marginTop: "5px" }} htmlFor="clasif">Clasificación</label>
                                            <select id="clasif" {...register("clasif", { required: true })}>
                                                <option value="1">Raw Material</option>
                                                <option value="2">Online Sales</option>
                                                <option value="3">Dropshipping</option>
                                                <option value="4">CoGS Dropshipping</option>
                                                <option value="5">COGS Online Sales</option>
                                                <option value="6">Transporte</option>
                                                <option value="7">Alquiler</option>
                                                <option value="8">Otros Gastos</option>
                                                <option value="9">Servicios Consultoria</option>
                                                <option value="10">Servicios Notaria</option>
                                                <option value="11">Servicios Asesores</option>
                                                <option value="12">Servicios Comerciales</option>
                                                <option value="13">Marketing</option>
                                                <option value="14">Suministros</option>
                                                <option value="15">Material de Oficina</option>
                                                <option value="16">Otros Tributos</option>
                                                <option value="17">R&D</option>
                                                <option value="18">Gastos Financieros</option>
                                                <option value="19">Ingresos Financieros</option>
                                                <option value="20">Resultados Extraordinarios</option>
                                            </select>
                                        </div>
                                        {isCuentaRequired && (
                                        <div className="form-group" style={{ marginBottom: "1rem", flex: "0 0 48%", width: "50%" }}>
                                            <label htmlFor="netsuite_account_id" style={{ marginTop: "5px" }}>Cuenta</label>
                                            <Controller
                                                name="netsuite_account_id"
                                                control={control}
                                                rules={{ required: true }}
                                                defaultValue={null}
                                                render={({ field }) => (
                                                    <Autocomplete
                                                        {...field}
                                                        options={flattenArray(selectedCuentaNombre)}
                                                        getOptionLabel={(option) => option.nombre}
                                                        onChange={(event, newValue) => {
                                                            setValue("netsuite_account_id", newValue ? newValue.id : null);
                                                            setSelectedCuenta(newValue ? newValue.id : null);
                                                        }}
                                                        value={flattenArray(selectedCuentaNombre).find((item) => item.id === selectedCuenta) || null}
                                                        renderInput={(params) => (
                                                            <TextField
                                                                {...params}
                                                                label="Selecciona una cuenta"
                                                                variant="outlined"
                                                            />
                                                        )}
                                                        noOptionsText="No hay opciones para los criterios introducidos"
                                                    />
                                                )}
                                            />
                                            <div className="form-invalid">
                                                {(errors.netsuite_account_id) && "Selecciona una cuenta"}
                                            </div>
                                        </div>
                                        )}
                                        <div className="form-group" style={{ marginBottom: "0rem", display: "block" }}>
                                            <label htmlFor="num">IVA NO deducible</label>&nbsp;
                                            <input id="iva_deducible" type="checkbox" {...register("iva_deducible", { required: false })} />
                                        </div>
                                        <div className="form-group" style={{ marginBottom: "1rem", display: "block" }}>
                                            <label htmlFor="num">Pagar a final de mes</label>&nbsp;
                                            <input id="pago_fin_mes" type="checkbox" {...register("pago_fin_mes", { required: false })} />
                                        </div>
                                        <div className="form-group" style={{ marginBottom: "0rem", flex: "0 0 48%", width: "50%" }}>
                                            <label htmlFor="num">Número de abono</label>
                                            <input
                                                id="num"
                                                type="text"
                                                defaultValue={abono ? abono.numero : ''}
                                                {...register("numero", { required: true })}
                                            />
                                            <div className="form-invalid">
                                                {(errors.numero) && "Introduce un numero de abono"}
                                            </div>
                                        </div>
                                        <div className="form-group" style={{ marginBottom: "0rem", flex: "0 0 48%", width: "50%" }}>
                                            <label htmlFor="fechaMovimiento">Fecha Movimiento</label>
                                            <input
                                                id="fechaMovimiento"
                                                type="text"
                                                {...register("dateMovimiento", { required: false })}
                                                placeholder="dd/mm/yyyy"
                                            />
                                        </div>
                                        <div className="form-group" style={{ marginBottom: "0rem", flex: "0 0 48%", width: "50%" }}>
                                            <label style={{ marginTop: "12px" }} htmlFor="fecha">Fecha Abono</label>
                                            <input
                                                id="fecha"
                                                type="text"
                                                defaultValue={abono && abono.fecha ? format(new Date(abono.fecha), 'dd/MM/yyyy') : ''}
                                                {...register("date", { required: true })}
                                                placeholder="dd/mm/yyyy"
                                            />
                                            <div className="form-invalid">
                                                {(errors.date) && "Introduce una fecha"}
                                            </div>
                                        </div>
                                        <div className="form-group" style={{ marginBottom: "0rem", flex: "0 0 48%", width: "50%" }}>
                                            <label style={{ marginTop: "12px" }} htmlFor="base">Base Imp.</label>
                                            <input
                                                id="base"
                                                type="text"
                                                defaultValue={abono ? abono.base : ''}
                                                {...register("base", { required: false })}
                                            />
                                            <div className="form-invalid">
                                                {(errors.base) && "Introduce una base imponible"}
                                            </div>
                                        </div>
                                        <div className="form-group" style={{ marginBottom: "0rem", flex: "0 0 48%", width: "50%" }}>
                                            <label style={{ marginTop: "12px" }} htmlFor="tipo_iva">Tipo IVA</label>
                                            <select id="tipo_iva" {...register("tipo_iva", { required: false })}>
                                                <option value="21">21%</option>
                                                <option value="10">10%</option>
                                                <option value="0">0%</option>
                                            </select>
                                        </div>
                                        <div className="form-group" style={{ marginBottom: "0rem", flex: "0 0 48%", width: "50%" }}>
                                            <label style={{ marginTop: "12px" }} htmlFor="iva">IVA</label>
                                            <input
                                                id="iva"
                                                type="text"
                                                defaultValue={abono ? abono.iva : ''}
                                                {...register("iva", { required: false })}
                                            />
                                            <div className="form-invalid">
                                                {(errors.iva) && "Introduce un IVA"}
                                            </div>
                                        </div>
                                        <div className="form-group" style={{ marginBottom: "0rem", flex: "0 0 48%", width: "50%" }}>
                                            <label style={{ marginTop: "12px" }} htmlFor="tipo_retencion">Retención</label>
                                            <select id="tipo_retencion" {...register("tipo_retencion", { required: false })}>
                                                <option value="">Selecciona el tipo de Retención</option>
                                                <option value="21">21%</option>
                                                <option value="19">19%</option>
                                                <option value="15">15%</option>
                                                <option value="10">10%</option>
                                                <option value="7">7%</option>
                                            </select>
                                        </div>
                                        {tipoRetencionSeleccionada && (
                                            <div className="form-group" style={{ marginBottom: "0rem", flex: "0 0 48%", width: "50%" }}>
                                                <label style={{ marginTop: "12px" }} htmlFor="baseNoSujeta">Base No Sujeta</label>
                                                <input id="baseNoSujeta" type="text" {...register("baseNoSujeta", { required: false })} onChange={handleChangeBaseNoSujeta} />
                                                <div className="form-invalid">
                                                    {(errors.baseNoSujeta) && "Introduce una base no sujeta!"}
                                                </div>
                                            </div>
                                        )}
                                        <div className="form-group" style={{ marginBottom: "0rem", flex: "0 0 48%", width: "50%" }}>
                                            <label style={{ marginTop: "12px" }} htmlFor="total">Importe total con IVA</label>
                                            <input
                                                id="total"
                                                type="text"
                                                defaultValue={abono ? abono.total : ''}
                                                {...register("total", { required: true })}
                                                onChange={handleChangeTipoIva}
                                            />
                                            <div className="form-invalid">
                                                {(errors.total) && "Introduce un importe total!"}
                                            </div>
                                        </div>
                                        <div className="form-group" style={{ marginTop: "2.2rem", width: "50%" }}>
                                            <button type="submit" className="btn btn-primary">  {abono && abono.id ? 'Asociar' : 'Crear Abono'}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                            <Col md={9}>
                                {viewerSrc ? (
                                    // Utiliza un elemento iframe u object según el tipo de archivo
                                    viewerSrc.endsWith(".pdf") ? (
                                        <>
                                            <button className="btn btn-primary" onClick={handleCloseViewer}>Cerrar Visor</button>
                                            <iframe title="PDF Viewer" src={viewerSrc} width="100%" height="100%"></iframe></>
                                    ) : (
                                        <>
                                            <button className="btn btn-primary" onClick={handleCloseViewer}>Cerrar Visor</button>
                                            <object data={viewerSrc} type="application/pdf" width="100%" height="100%">
                                                <p>Visor no compatible con el tipo de archivo.</p>
                                            </object>
                                        </>
                                    )
                                ) : (
                                    <>
                                        <div className="flex flex-row mb-4 space-x-2">
                                            <div className="flex flex-col flex-1" style={customWidth}>
                                                <Autocomplete
                                                    value={estados.find((option) => option.value === selectedEstado) || estados[0]}
                                                    options={estados}
                                                    getOptionLabel={(option) => option.label}
                                                    onChange={(event, newValue) => {
                                                        setSelectedEstado(newValue.value);
                                                    }}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            label="Filtrar por Estado"
                                                            variant="outlined" />
                                                    )}
                                                    disableClearable
                                                />
                                            </div>

                                            <div className="flex flex-col flex-1" style={customWidth}>
                                                <Autocomplete
                                                    value={selectedDesc}
                                                    options={flattenArray(filters.dDesc)}
                                                    getOptionLabel={(option) => option?.description || ""}
                                                    onChange={(event, newValue) => {
                                                        setSelectedDesc(newValue);
                                                    }}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            label="Filtrar por Tipo.."
                                                            variant="outlined"
                                                        />
                                                    )}
                                                />
                                            </div>

                                            <div style={customWidth} className="flex flex-col flex-1">
                                                <Autocomplete
                                                    value={selectedNombre}
                                                    options={flattenArray(filters.dNombre)}
                                                    getOptionLabel={(option) => option?.nombre || ""}
                                                    onChange={(event, newValue) => {
                                                        setSelectedNombre(newValue);
                                                    }}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            label="Filtrar por Nombre.."
                                                            variant="outlined" />
                                                    )}
                                                />
                                            </div>

                                            <div style={customWidth} className="flex flex-col flex-1">
                                                <Autocomplete
                                                    value={selectedCifnif}
                                                    options={flattenArray(filters.dCifnif)}
                                                    getOptionLabel={(option) => option?.cifnif || ""}
                                                    onChange={(event, newValue) => {
                                                        setSelectedCifnif(newValue);
                                                    }}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            label="Filtrar por CIF/NIF"
                                                            variant="outlined" />
                                                    )}
                                                />
                                            </div>

                                            <div style={{ display: 'inline-block', flexDirection: 'column', width: customWidth }} className="flex flex-col flex-1">
                                                <input style={{ verticalAlign: 'text-top' }} type="date" id="date-range" name="startDate" value={dateRange.startDate} onChange={handleDateChange} />
                                                <input style={{ verticalAlign: 'text-top' }} type="date" name="endDate" value={dateRange.endDate} onChange={handleDateChange} />
                                                <button onClick={handleReset}>Reset</button>
                                            </div>
                                        </div>
                                        <div className="mb-2">
                                            Asociar a movimiento
                                            <div className="mt-1 table-responsive">
                                                <table className="mt-1 table">
                                                    <thead>
                                                        <tr>
                                                            <th>
                                                                <div>
                                                                    <span></span>
                                                                </div>
                                                            </th>
                                                            <th>
                                                                <div>
                                                                    <span>Estado</span>
                                                                </div>
                                                            </th>
                                                            <th>
                                                                <div>
                                                                    <span>Fecha</span>
                                                                </div>
                                                            </th>
                                                            <th>
                                                                <div>
                                                                    <span>Nombre</span>
                                                                </div>
                                                            </th>
                                                            <th>
                                                                <div>
                                                                    <span>Cif o nif</span>
                                                                </div>
                                                            </th>
                                                            <th>
                                                                <div>
                                                                    <span>Tipo</span>
                                                                </div>
                                                            </th>
                                                            <th>
                                                                <div>
                                                                    <span>Base</span>
                                                                </div>
                                                            </th>
                                                            <th>
                                                                <div>
                                                                    <span>Iva</span>
                                                                </div>
                                                            </th>
                                                            <th>
                                                                <div>
                                                                    <span>Total</span>
                                                                </div>
                                                            </th>
                                                            <th>
                                                                <div>
                                                                    <span>Restante</span>
                                                                </div>
                                                            </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {movimientos.length ? (
                                                            getRows(movimientos)
                                                        ) : (
                                                            <tr>
                                                                <td colSpan="10">
                                                                    No hay movimientos
                                                                </td>
                                                            </tr>
                                                        )}
                                                    </tbody>
                                                </table>
                                                <Pagination
                                                    className="mt-3"
                                                    count={pageCount}
                                                    page={currentPage}
                                                    onChange={handleChangePage}
                                                />
                                            </div>
                                        </div> </>
                                )}
                            </Col>
                        </div>
                    </form>
                </Tab>

                <Tab eventKey="lista" title="Lista de Abonos">
                    <div className="flex flex-row mb-4 space-x-2">
                        <div className="flex flex-col flex-1" style={customWidthAbonos}>
                            <Autocomplete
                                value={selectedMov}
                                onChange={(event, newValue) => {
                                    setSelectedMov(newValue);
                                }}
                                options={[
                                    { value: "", label: "Filtrar por Asociado a Movimiento" },
                                    { value: ">0", label: "Asociada" },
                                    { value: "0", label: "No asociada" }
                                ]}
                                getOptionLabel={(option) => option.label}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Filtrar por Asociado a Movimiento"
                                        variant="outlined"
                                    />
                                )}
                                disableClearable
                                noOptionsText="No hay opciones para los criterios introducidos"
                            />
                        </div>

                        <div className="flex flex-col flex-1" style={customWidthAbonos}>
                            <Autocomplete
                                value={selectedEmpresa}
                                onChange={(event, newValue) => {
                                    setSelectedEmpresa(newValue);
                                }}
                                options={[
                                    { value: "", label: "Filtrar por todas las empresa" },
                                    { value: "1", label: "JG RECOMOTOR S.L." },
                                    { value: "3", label: "MICAL AUTOCASION SL" },
                                    { value: "4", label: "DESGUACES VICEN 2016 SL" }
                                ]}
                                getOptionLabel={(option) => option.label}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Empresa"
                                        variant="outlined"
                                    />
                                )}
                                noOptionsText="No hay opciones para los criterios introducidos"
                            />
                        </div>

                        <div className="flex flex-col flex-1" style={customWidthAbonos}>
                            <Autocomplete
                                value={selectedNumero}
                                onChange={(event, newValue) => {
                                    setSelectedNumero(newValue);
                                }}
                                options={[
                                    ...flattenArray(filtersAbonos.dNumero).map((item) => ({ value: item.numero, label: item.numero }))
                                ]}
                                getOptionLabel={(option) => option.label}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Número de abono"
                                        variant="outlined"
                                    />
                                )}
                                noOptionsText="No hay opciones para los criterios introducidos"
                            />
                        </div>

                        <div style={customWidthAbonos} className="flex flex-col flex-1">
                            <Autocomplete
                                value={selectedIdProv}
                                onChange={(event, newValue) => {
                                    setSelectedIdProv(newValue);
                                }}
                                options={[
                                    ...flattenArray(filtersAbonos.dNombre).map((item) => ({ value: item.id, label: item.nombre }))
                                ]}
                                getOptionLabel={(option) => option.label}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Proveedor"
                                        variant="outlined"
                                    />
                                )}
                                noOptionsText="No hay opciones para los criterios introducidos"
                            />
                        </div>
                        <div className="flex flex-col flex-1" style={customWidth}>
                            <Autocomplete
                                value={selectedPFM}
                                onChange={(event, newValue) => {
                                    setSelectedPFM(newValue);
                                }}
                                options={[
                                    { value: "0", label: "Todos los pagos" },
                                    { value: "1", label: "Pagar a fin de mes" }
                                ]}
                                getOptionLabel={(option) => option.label}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Filtrar por pago"
                                        variant="outlined"
                                    />
                                )}
                                disableClearable
                                noOptionsText="No hay opciones para los criterios introducidos"
                            />
                        </div>
                        <div style={{ display: 'inline-block', flexDirection: 'column', width: customWidth }} className="flex flex-col flex-1">
                            <input style={{ verticalAlign: 'text-top' }} type="date" id="date-range" name="startAbonoDate" value={dateAbonoRange.startAbonoDate} onChange={handleDateAbonoChange} />
                            <input style={{ verticalAlign: 'text-top' }} type="date" name="endAbonoDate" value={dateAbonoRange.endAbonoDate} onChange={handleDateAbonoChange} />
                            <button onClick={handleAbonoReset}>Reset</button>
                        </div>
                    </div>
                    <div className="mb-2">
                        <div className="mt-1 table-responsive">
                            <table className="mt-1 table">
                                <thead>
                                    <tr>
                                        <th>
                                            <div>
                                                <span></span>
                                            </div>
                                        </th>
                                        <th>
                                            <div>
                                                <span>Número</span>
                                            </div>
                                        </th>
                                        <th>
                                            <div>
                                                <span>Fecha</span>
                                            </div>
                                        </th>
                                        <th>
                                            <div>
                                                <span>Nombre</span>
                                            </div>
                                        </th>
                                        <th>
                                            <div>
                                                <span>Base</span>
                                            </div>
                                        </th>
                                        <th>
                                            <div>
                                                <span>Iva</span>
                                            </div>
                                        </th>
                                        <th>
                                            <div>
                                                <span>Total</span>
                                            </div>
                                        </th>
                                        <th>
                                            <div>
                                                <span>Documento</span>
                                            </div>
                                        </th>
                                        <th>
                                            <div>
                                                <span>Eliminar</span>
                                            </div>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {abonos.length ? (
                                        getRowsAbonos(abonos)
                                    ) : (
                                        <tr>
                                            <td colSpan="9">
                                                No hay abonos
                                            </td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                            <Pagination
                                className="mt-3"
                                count={pageCountAbonos}
                                page={currentPageAbono}
                                onChange={handleChangePageAbonos}
                            />
                        </div>
                    </div>
                </Tab>
            </Tabs>
        </div>
    );
}
